import React from "react"
import { Layout } from "../../components/common/layout"
import { Banner } from "../../components/common/banner"
import { Intent } from "../../components/common/intent"
import backgroundIMG from "../../components/assets/img/pages/solucoes/subpages/ic-logistica-gestao-de-frotas.jpg"
import orangeArrowImage from "../../components/assets/img/ic-orange-arrow.png"

const LogisticaPage = () => (
    <Layout infoPage={{ page: "Logística" }}>
        <main className="solucoes-subpage">
            <Banner backgroundImage={backgroundIMG}>
                <h1 className="title bold">
                    LOGÍSTICA
                    <br />
                    <span className="bold" style={{ color: "#FFF" }}>
                        GESTÃO DE FROTAS
                    </span>
                </h1>
                <div className="gray-box">
                    <span className="text">
                        Atuamos com tecnologia em RFID, IoT – Internet das
                        Coisas e IA – Inteligência Artificial, para atender o
                        mercado logístico com soluções para gestão de frotas.{" "}
                    </span>
                </div>
            </Banner>
            <section className="content">
                <p className="text">
                    Utilizamos tecnologias de ponta para reduzir o custo do KM
                    rodado da operação logística, nosso sistema de controle
                    automotivo e gestão efetiva da frota é individualizada por
                    veículo e operador.
                </p>
                <p className="text">
                    <span className="bold orange">
                        Nosso sistema gera informações sobre:
                    </span>
                </p>
                <ul className="list">
                    <li className="text">
                        horas de direção contínua do operador
                    </li>
                    <li className="text">
                        data e hora da operação, em movimento e de paradas
                    </li>
                    <li className="text">
                        coordenadas de latitude e longitude ao longo da operação
                        (GPS)
                    </li>
                    <li className="text">telemetria veicular</li>
                    <li className="text">
                        alarme de grau de inclinação do veículo
                    </li>
                    <li className="text">quilometragem acumulada</li>
                    <li className="text">velocidade média e máxima</li>
                    <li className="text">total de ultrapassagens</li>
                    <li className="text">tempo em movimento e parado</li>
                    <li className="text">veículo parado e ou desligado</li>
                    <li className="text">
                        gasto total de combustível quando veículo parado, em
                        aquecimento e em movimento
                    </li>
                    <li className="text">
                        tempo de viagem com velocidade e quilometragem
                        percorrida acima da permitida
                    </li>
                    <li className="text">
                        quantidade de paradas programadas realizadas e não
                        realizadas
                    </li>
                    <li className="text">sensor de frenagens bruscas</li>
                    <li className="text">
                        sensor de velocidade em aclive e declive
                    </li>
                    <li className="text">
                        sensor de distância em relação ao veículo à frente
                    </li>
                    <li className="text">distância legal</li>
                    <li className="text">
                        acelerações bruscas (grau de risco do motorista e da
                        carreta)
                    </li>
                    <li className="text">desgastes das rodovias</li>
                    <li className="text">
                        detector de manobras de alto risco (quebra de asa)
                    </li>
                    <li className="text">estação meteorológica embarcada</li>
                </ul>
                <p className="text">
                    Com este conjunto de dados, o nosso sistema gera relatórios
                    de métricas e indicadores do operador e do desgaste do
                    veículo para predição de manutenção veicular e qualificação
                    dos operadores.
                </p>
                <a
                    href="http://sowx.com.br/assets/Sowx-RFID-e-IDRIVING.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="flex-box"
                    style={{ marginTop: "1rem" }}
                >
                    <img
                        className="arrow"
                        src={orangeArrowImage}
                        alt="arrow"
                        style={{
                            width: "1.25rem",
                            height: "1.25rem",
                            marginRight: "0.5rem",
                        }}
                    />
                    <span
                        style={{
                            color: "#000",
                            fontStyle: "italic",
                            fontSize: "1.75rem",
                        }}
                    >
                        Clique e baixe o material completo sobre RFID e IDRIVING
                    </span>
                </a>
                <Intent classes="button" to="/#solutions">
                    <span className="medium">DEMAIS SOLUÇÕES</span>
                </Intent>
            </section>
        </main>
    </Layout>
)

export default LogisticaPage
